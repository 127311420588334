const mixin = {
  methods: {
    tStatus: function (status) {
      return this.statuses.find((s) => s.value == status)?.text || '?';
    }
  },
  computed: {
    statuses() {
      return [
        { value: 'new', text: this.$t('orders.status.new') },
        { value: 'checked', text: this.$t('orders.status.checked') },
        { value: 'ready to print', text: this.$t('orders.status.ready') },
        { value: 'in production', text: this.$t('orders.status.in-prod') },
        { value: 'on hold', text: this.$t('orders.status.on-hold') },
        { value: 'finished', text: this.$t('orders.status.finished') },
        { value: 'shipped', text: this.$t('orders.status.shipped') },
        { value: 'canceled', text: this.$t('orders.status.canceled') }
      ];
    }
  }
};
export default mixin;
