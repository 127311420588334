import request from './request';

export default {
  async postOrder(order) {
    const url = `/api/orders/`;
    console.log('POST ', url);
    const result = await request.request(url, 'post', order, {});

    console.log('result: ', result);
    return result;
  },

  async uploadOrder(file) {
    let url = `/api/upload/order/xml`;
    let req = new FormData();
    req.append('file', file);
    console.log('POST ', url);

    const rsp = await request.request(url, 'post', req);
    if (rsp['status'] !== 200) {
      console.log('Error with uploading a file:', rsp);
      throw 'Error while uploading file; status_code=' + rsp['status'];
    }
    return rsp;
  },

  appendSearch(url, opts, search) {
    // console.log('opts:', opts);
    opts ||= {};
    let qp = [];
    if (search?.length > 0) {
      qp.push(`search=${search}`);
    }
    if (opts.archived !== undefined) {
      qp.push(`archived=${opts.archived}`);
    }

    if (opts.page) {
      qp.push(`page=${opts.page}`);
    }
    if (opts.itemsPerPage) {
      qp.push(`size=${opts.itemsPerPage}`);
    }

    if (opts.filters) {
      for (let filter in opts.filters) {
        let f = opts.filters[filter];
        if (opts.filters[filter].field == 'die_cut_id')
          f.field = 'die_cut__name';
        if (opts.filters[filter].field == 'braille_id')
          f.field = 'braille__name';
        if (opts.filters[filter].field == 'client_id')
          f.field = 'client__external_id';
        if (opts.filters[filter].field == 'client_ro') f.field = 'client__name';

        qp.push(`${f.field}${f.operation}${f.value}`);
      }
    }
    let sort = [];
    for (let i = 0; i < opts.sortBy?.length; i++) {
      let sign = '';
      if (i < opts.sortDesc.length && opts.sortDesc[i]) {
        sign += '-';
      }
      let field = opts.sortBy[i].replace('.', '__');

      if (field == 'die_cut') {
        field = 'die_cut__name';
      } else if (field == 'client') {
        field = 'client__name';
      }

      sort.push(sign + field);
    }
    if (sort.length > 0) {
      qp.push(`ordering=${sort.join(',')}`);
    }

    let qps = qp.join('&');
    if (qps.length > 0) {
      url += '?' + qps;
    }

    return url;
  },

  //http://127.0.0.1:8000/api/orders/?page=3&size=2
  async getOrders(opts, search) {
    let url = `/api/orders/`;
    url = this.appendSearch(url, opts, search);

    console.log('GET', url);
    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async patchOrder(order) {
    let id = order.id;
    const url = `/api/orders/${id}/`;
    console.log('PATCH ', url, 'order:', order);

    const result = await request.request(url, 'patch', order, {});

    console.log('result: ', result);
    return result;
  },

  async deleteOrder(order) {
    let id = order.id;
    const url = `/api/orders/${id}/`;
    console.log('DELETE ', url);

    const result = await request.request(url, 'delete', {}, {});

    console.log('result: ', result);
    return result;
  },

  async reparseOrder(order) {
    let id = order.id;
    const url = `/api/orders/${id}/reparse/`;
    console.log('PUT ', url);
    const result = await request.request(url, 'put', {}, {});

    console.log('result: ', result);
    return result;
  },

  async archive(order) {
    let id = order.id;
    const url = `/api/orders/${id}/archive/`;
    console.log('PUT ', url);

    const result = await request.request(
      url,
      'put',
      {
        archived: order.archived
      },
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async setStatus(order, status) {
    let id = order.id;
    const url = `/api/orders/${id}/set_status/`;
    console.log('PUT ', url);

    const result = await request.request(
      url,
      'put',
      {
        status: status
      },
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async uploadAttachment(order, file) {
    let url = `/api/orders/${order.id}/upload_attachment/`;
    console.log('POST ', url);

    let req = new FormData();
    req.append('file', file);

    const rsp = await request.request(url, 'post', req);
    if (rsp['status'] !== 200) {
      console.log('Error with uploading a file:', rsp);
      throw 'Error while uploading file; status_code=' + rsp['status'];
    }
    return rsp;
  },

  async downloadAttachment(order, path) {
    let id = order.id;
    const url = `/api/orders/${id}/download_attachment/?path=${path}`;
    console.log('GET ', url);
    const rsp = await request.request(url, 'get', {}, {});
    console.log(rsp);
    return rsp.data;
  },

  async downloadAttachments(order, paths) {
    let id = order.id;
    const url = `/api/orders/${id}/download_attachments/`;
    console.log('POST ', url);
    const req = {
      keys: paths
    };
    const rsp = await request.request(url, 'post', req);
    // console.log(rsp);
    return rsp.data;
  },

  async listAttachments(order) {
    let url = `/api/orders/${order.id}/attachments/`;
    console.log('GET ', url);
    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async deleteAttachment(order, attachment) {
    let url = `/api/orders/${order.id}/attachments/${attachment.id}`;
    console.log('DELETE ', url);
    return await request.request(url, 'delete', {}, {});
  },

  async excel(opts, search) {
    let url = `/api/orders/excel/`;
    url = this.appendSearch(url, opts, search);

    console.log('download ', url);

    await request.download(url, 'orders.xlsx');
  },

  async getCardboardDemand() {
    const url = `/api/orders/get_cardboard_demand/`;
    console.log('GET ', url);

    let rsp = await request.request(url, 'get', {}, {});
    return rsp.data;
  },

  async getStats() {
    const url = `/api/orders/get_stats/`;
    console.log('GET ', url);

    let rsp = await request.request(url, 'get', {}, {});
    return rsp.data;
  },

  async AddMaterialPart(order, part) {
    console.log(`Add material part ${part} to order ${order.order_no}`);

    const url = `/api/orders/${order.id}/add_material_part/`;
    console.log('PUT ', url);

    const result = await request.request(
      url,
      'put',
      {
        part_id: part
      },
      {}
    );

    return result;
  },

  async RemoveMaterialPart(order, part) {
    console.log(`Remove material part ${part} from order ${order.order_no}`);

    const url = `/api/orders/${order.id}/remove_material_part/`;
    console.log('PUT ', url);

    const result = await request.request(
      url,
      'put',
      {
        part_id: part
      },
      {}
    );

    return result;
  }
};
