<template>
  <v-layout row wrap>
    <v-card
      v-for="(m, i) in progress"
      :key="i"
      class="pa-0 ma-0"
      :color="color(m)"
      elevation="1"
      align="center"
      justify="center"
    >
      <v-card-title
        class="pt-0 pb-0 pl-1 pr-1 ma-0 justify-center"
        align="center"
      >
        {{ m['machine'] }}
      </v-card-title>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  props: {
    progress: {
      type: Array,
      required: true
    }
  },
  methods: {
    color(m) {
      switch (m?.status) {
        case 'in production':
          return 'blue';
        case 'finished':
          return 'green';
        case 'on hold':
          return 'red';
        default:
          if (m.quantity_done > 0) return 'light-blue';
          return 'gray';
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.v-card__title
    font-size: 12px
</style>
