<template>
  <div class="Orders">
    <template>
      <v-card elevation="0" class="mx-2">
        <v-row>
          <v-col v-if="!limit" cols="2" class="ml-3">
            <v-icon
              medium
              @click="
                () => {
                  searchVisible = (search && search.length) || !searchVisible;
                }
              "
            >
              {{ searchVisible ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            {{ $t('search') }}
          </v-col>
          <v-col v-if="!limit" cols="2" class="ml-3">
            <v-icon
              medium
              @click="
                () => {
                  filterVisible = anyFilter() || !filterVisible;
                }
              "
            >
              {{ filterVisible ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            {{ $t('orders.filters') }}
          </v-col>

          <v-col v-if="!limit" cols="2" class="ml-3">
            <v-menu v-if="!limit" offset-y>
              <template #activator="{ on, attrs }">
                <v-icon medium v-bind="attrs" v-on="on"> mdi-plus </v-icon>
                {{ $t('orders.actions') }}
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in actions"
                  :key="i"
                  link
                  @click="() => item.handler()"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-row v-if="(searchVisible || (search && search.length)) && !limit">
          <v-col cols="11" class="ml-3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              clearable
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="11" class="ml-3" v-if="filterVisible && !limit">
            <filters
              :filters="options.filters || []"
              @changed="onFiltersUpdated"
            ></filters>
          </v-col>
        </v-row>

        <status-card v-if="!limit" />

        <v-tabs v-model="tab" class="mb-3">
          <v-tab :disabled="loading">
            {{ $t('orders.header') }}
          </v-tab>
          <v-tab :disabled="loading">
            {{ $t('archived') }}
          </v-tab>
        </v-tabs>
        <v-data-table
          v-model="selected"
          dense
          :loading="loading"
          :loading-text="$t('loading')"
          :headers="headers"
          :items="orders"
          :show-select="!limit"
          :footer-props="{
            'items-per-page-options': [20, 40, 60, 80, 100],
            'items-per-page-text': $t('table.per-page')
          }"
          :no-data-text="$t('table.no-data')"
          :items-per-page="100"
          :search="search"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          :options.sync="options"
          :server-items-length="total"
          :item-class="row_classes"
          @click:row="expandRow"
        >
          <template #top>
            <v-dialog v-model="logDialog">
              <log :item="editedItem" @close="onCloseLog" />
            </v-dialog>
            <v-toolbar flat v-if="toolbarVisible">
              <v-row align-self="end">
                <v-col class="d-flex justify-space-between align-center">
                  <div>
                    <div>
                      <v-btn
                        v-if="tab == 0"
                        color="primary"
                        dark
                        class="mb-2"
                        @click="updateArchivedFlag(true)"
                      >
                        {{ $t('archive') }}
                        <v-icon right dark> mdi-archive </v-icon>
                      </v-btn>

                      <v-btn
                        v-if="tab == 1"
                        color="primary"
                        dark
                        class="mb-2"
                        @click="updateArchivedFlag(false)"
                      >
                        {{ $t('restore') }}
                        <v-icon right dark> mdi-restore</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-toolbar>

            <v-dialog v-model="uploadDialog">
              <upload
                :title="$t('orders.upload-xml-order')"
                mime="application/xml"
                :statuses="uploadStatuses"
                @upload="onUpload"
                @close="onUploadClosed"
              />
            </v-dialog>

            <config-table
              v-model="tableConfigDialog"
              :all="allFields"
              :selected="selectedFields"
              @selection="onColumnsSelected"
            />
          </template>
          <template #[`item.client`]="{ item }">
            <div :class="getClientColor(item)">
              {{ client(item) }}
            </div>
          </template>
          <template #[`item.die_cut`]="{ item }">
            <div :class="getDieCutColor(item)" class="pa-2">
              {{ die_cut(item) }}
            </div>
          </template>
          <template #[`item.braille_enabled`]="{ item }">
            <div :class="item.braille_enabled ? 'braille' : ''">
              {{ braille_enabled(item) }}
            </div>
          </template>
          <template #[`item.due_date`]="{ item }">
            <span>{{ dueDate(item) }}</span>
          </template>
          <template #[`item.when_created`]="{ item }">
            <span>{{ whenCreated(item) }}</span>
          </template>
          <template #[`item.status`]="{ item }">
            <div
              v-if="editStatusInline != item"
              :class="getStatusColor(item)"
              class="pa-2"
              @click="
                () => {
                  if (limit) return;
                  if (!item.archived) editStatusInline = item;
                }
              "
            >
              {{ tStatus(item.status) }}
            </div>
            <div v-else>
              <v-select
                v-model="item.status"
                :items="statuses"
                :menu-props="{ maxHeight: '400' }"
                persistent-hint
                dense
                @change="onStatusUpdated(item)"
              ></v-select>
            </div>
          </template>
          <template #[`item.progress`]="{ item }">
            <squares
              :key="refreshKey + item.id"
              :progress="progress[item.id] || []"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              v-if="hasPermission('api.change_order') && (enableEdit || !limit)"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="hasPermission('api.view_orderlog')"
              small
              @click.stop="onShowLog(item)"
            >
              mdi-history
            </v-icon>
            <v-icon
              v-if="hasPermission('api.delete_order') && !limit"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <more-info :item="item" :headers="headers" />
            </td>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>
import OrderService from '@/services/OrderService.js';
import PlanService from '@/services/PlanService.js';
import squares from './progressSquares.vue';
import statusCard from './statusCard.vue';
import OrderMixin from './orderMixin.js';
import filters from './orderFilters.vue';
import store from '@/store';
import Users from '@/services/UserService.js';

export default {
  components: {
    moreInfo: () => import('@/views/dashboard/components/orders/moreInfo.vue'),
    upload: () => import('@/views/dashboard/components/files/upload.vue'),
    log: () => import('@/views/dashboard/components/orders/orderLog.vue'),
    configTable: () => import('./tableConfiguration.vue'),
    filters,
    squares,
    statusCard
  },
  mixins: [OrderMixin],
  props: {
    searchPhrase: {
      type: String,
      required: false,
      default: ''
    },
    limit: {
      type: Boolean,
      required: false
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      searchTimeout: null,

      loading: false,
      searchVisible: false,
      search: '',
      buttonsVisible: false,
      tableConfigDialog: false,

      filterVisible: false,
      selected: [],
      refreshKey: 0,
      expanded: [],

      orders: [],
      progress: {},
      logDialog: false,

      uploadDialog: false,
      uploadStatuses: [],

      editedIndex: -1,
      editedItem: {},
      editStatusInline: null,

      refreshInterval: null,
      refreshOn: true,

      tab: 0,

      options: {},
      total: 0,

      allFields: [
        {
          text: 'orders.priority',
          value: 'priority',
          sortable: true
        },

        {
          text: 'orders.client',
          value: 'client',
          sortable: true
        },
        {
          text: 'orders.order-no',
          align: 'start',
          value: 'order_no',
          sortable: true
        },
        {
          text: 'orders.client-no',
          value: 'client_order_no',
          sortable: true
        },
        {
          text: 'orders.item-no',
          value: 'item_no',
          sortable: true
        },
        {
          text: 'orders.description',
          value: 'description',
          sortable: true
        },
        {
          text: 'orders.quantity',
          value: 'quantity',
          sortable: true,
          align: 'right'
        },
        {
          text: 'orders.diecut',
          value: 'die_cut',
          sortable: true
        },
        {
          text: 'orders.braille',
          value: 'braille_enabled',
          sortable: true
        },
        {
          text: 'orders.created_at',
          value: 'when_created',
          sortable: true
        },
        {
          text: 'orders.due-date',
          value: 'due_date',
          sortable: true
        },
        {
          text: 'orders.field.lot_no',
          value: 'lot_no',
          sortable: false
        },
        {
          text: 'comments',
          value: 'comments',
          sortable: true
        },
        {
          text: 'status',
          value: 'status',
          sortable: true
        },
        {
          text: 'orders.progress',
          value: 'progress',
          sortable: false,
          width: '150px'
        }
      ]
    };
  },
  computed: {
    filters() {
      return this.options?.filters;
    },
    selectedFields() {
      if (!store.state.session.user) {
        return this.allFields;
      }
      const profile = store.state.session.user.profile;
      let { order_list = {} } = profile;
      let { columns = [] } = order_list;

      if (columns.length > 0) {
        return columns.map((c) => this.allFields.find((f) => f.value === c));
      }
      return this.allFields;
    },
    headers() {
      let translated = this.selectedFields.map((i) => {
        return { ...i, text: this.$t(i.text) };
      });
      translated.push({
        text: this.$t('orders.actions'),
        value: 'actions',
        sortable: false,
        width: '5%'
      });
      return translated;
    },
    actions() {
      let all = [
        {
          text: this.$t('orders.new'),
          icon: 'mdi-plus',
          handler: () => {
            this.onNewOrder();
          },
          perm: 'api.add_order'
        },
        {
          text: this.$t('orders.upload'),
          icon: 'mdi-cloud-upload',
          handler: () => {
            this.onUploadOrderDialog();
          },
          perm: 'api.add_order'
        },
        {
          text: this.$t('orders.refresh'),
          icon: 'mdi-refresh',
          handler: () => {
            this.onRefresh();
          },
          perm: 'api.add_order'
        },
        {
          text: this.$t('export'),
          icon: 'mdi-file-excel',
          handler: () => {
            this.onExport();
          },
          perm: 'api.view_order'
        },
        {
          text: this.$t('table.table-config'),
          icon: 'mdi-table-column-remove',
          handler: () => {
            this.tableConfigDialog = true;
          },
          perm: 'api.view_order'
        }
      ];
      return all.filter((a) => this.hasPermission(a.perm));
    },
    toolbarVisible() {
      return this.hasPermission('api.change_order') && this.selected.length > 0;
    }
  },
  watch: {
    options: {
      async handler() {
        console.log('#options');
        await this.loadItems();
      },
      deep: true
    },
    search: {
      handler() {
        console.log('#search');
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 500);
      }
    },
    searchPhrase: {
      async handler() {
        console.log('#searchPhrase');
        await this.loadItems();
      }
    },
    async tab(tab, oldTab) {
      console.log('tab: ', oldTab, ' -> ', tab);
      this.selected = [];
      await this.loadItems();
    }
  },
  deactivated() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.refreshOn = false;
  },
  mounted() {
    this.$root.$on('order_updated', this.onOrderUpdated);
    this.$root.$on('order_added', this.onOrderAdded);
    // this.selectedFields = [...this.allFields].slice(1, 5);
  },
  beforeDestroy() {
    this.$root.$off('order_updated', this.onOrderUpdated);
    this.$root.$off('order_added', this.onOrderAdded);
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.refreshOn = false;
  },
  methods: {
    async loadItems() {
      this.loading = true;
      this.options.archived = this.tab == 1;

      let t0 = performance.now();
      OrderService.getOrders(
        this.options,
        this.searchPhrase ? this.searchPhrase : this.search ? this.search : ''
      )
        .then((response) => {
          [this.orders, this.total] = response;

          // console.log('orders: ', this.orders);

          this.orders = this.orders.map((o) => {
            o.when_created = this.dateFromISO8601(o.when_created);
            o.due_date = this.dateFromISO8601(o.due_date);
            return o;
          }, this);
          this.refresh(this);
          if (!this.refreshInterval && this.refreshOn) {
            this.refreshInterval = setInterval(() => {
              this.refresh(this);
            }, 20000);
          }

          this.$root.$emit('orders:total', this.total);
        })
        .catch((err) => {
          this.showError(this, err);
        })
        .finally(() => {
          let t1 = performance.now();
          console.log('getOrders took ' + (t1 - t0) + ' milliseconds.');
          this.loading = false;
        });
    },

    async refresh(self) {
      const ids = this.orders.map((o) => o.id);
      try {
        {
          const rsp = await PlanService.getProgress(ids);
          self.progress = rsp.reduce(
            (acc, o) => ((acc[o.order_id] ||= []).push(o), acc),
            {}
          );
          this.refreshKey = Date.now();
        }
      } catch (err) {
        console.log('err:', err);
      }
    },

    editItem(item) {
      this.$emit('edit', Object.assign({}, item));
    },

    onOrderAdded(order) {
      console.log('onOrderAdded:', order);
      this.orders.push(order);
    },

    onOrderUpdated(order) {
      console.log('onOrderUpdated:', order);
      let index = this.orders.findIndex((c) => c.id == order.id);
      if (index != -1) {
        Object.assign(this.orders[index], order);
      }
    },

    async deleteItem(item) {
      console.log('delete order:', item);
      const res = await this.$swal({
        html: this.$t('orders.delete-confirm'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.orders.indexOf(item);

      try {
        let result = await OrderService.deleteOrder(item);
        console.log('result: ', result);
        this.orders.splice(idx, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    onNewOrder() {
      this.$emit('new');
    },

    onShowLog(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.logDialog = true;
    },

    onCloseLog() {
      this.logDialog = false;
    },

    onUploadOrderDialog() {
      this.uploadDialog = true;
    },

    async onUpload(files) {
      console.log('orderList.onUpload:', files);
      await Promise.all(files.map((f) => this.uploadSingle(f)));
    },

    async onUploadClosed() {
      try {
        this.uploadDialog = false;
        await this.loadItems();
      } catch (err) {
        this.showError(this, err);
      }
    },

    async uploadSingle(f) {
      this.$set(this.uploadStatuses, f.id, 'uploading');
      try {
        await OrderService.uploadOrder(f.file);
        this.$set(this.uploadStatuses, f.id, 'done');
      } catch (err) {
        console.log('err:', err);
        const msg = err.message || err;
        this.$set(this.uploadStatuses, f.id, `error:${msg}`);
      }
    },

    async onRefresh() {
      await this.loadItems();
    },

    whenCreated(item) {
      return item.when_created?.toLocaleDateString() || '';
    },

    dueDate(item) {
      if (!item.due_date) return '';
      return item.due_date.toLocaleDateString();
    },

    client(item) {
      if (item.client_ro) return item.client_ro.short_name;
      return `${item.client_id}`;
    },

    getClientColor(item) {
      if (item.client_ro) return '';
      return 'missing';
    },

    die_cut(item) {
      if (item.die_cut_ro) return item.die_cut_ro.name;
      return item.die_cut_id;
    },

    braille_enabled(item) {
      if (item.braille_enabled == null) return '?';
      if (item.braille_enabled) return this.$t('yes');
      return this.$t('no');
    },

    getDieCutColor(item) {
      if (item.die_cut_ro) return '';
      return 'missing';
    },

    getStatusColor(item) {
      if (!item) return '';
      if (item.error) {
        return 'missing';
      }
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },

    async updateArchivedFlag(value) {
      // console.log('archive(', value, '):', this.selected);
      for (let o of this.selected) {
        o.archived = value;
        try {
          let res = await OrderService.archive(o);
          let order = res.data;
          if (order.archived == value) {
            let index = this.orders.findIndex((o) => o.id == order.id);
            this.orders.splice(index, 1);
            this.total--;
          }
        } catch (err) {
          console.log('archive order error:', err);
          this.showError(this, err);
        }
      }
      this.selected = [];
    },

    row_classes(item) {
      if (this.tab == 1) {
        // "archived" tab without colors
        return;
      }

      if (item.done) {
        return 'done';
      }

      // https://www.meistertask.com/app/task/5aJAdQK5/zamowienia-na-czerwono-pomimo-ze-nie-sa-opoznione
      if (item.due_date) {
        let due_date_aligned = item.due_date;
        due_date_aligned.setHours(0, 0, 0, 0);
        if (due_date_aligned <= Date.now()) {
          return 'late';
        }

        if (item.late) {
          return 'at-risk';
        }
      }
    },

    async onStatusUpdated(item) {
      console.log('status_updated:', item.status);
      try {
        let result = await OrderService.setStatus(item, item.status);
        let order = result.data;
        order.when_created = this.dateFromISO8601(order.when_created);
        order.due_date = this.dateFromISO8601(order.due_date);
        console.log('result: ', order);

        Object.assign(item, order);
      } catch (err) {
        this.showError(this, err);
        this.loadItems();
      }
    },

    async onExport() {
      await OrderService.excel(
        this.options,
        this.searchPhrase ? this.searchPhrase : this.search ? this.search : ''
      );
    },
    anyFilter() {
      return this.options?.filters?.length;
    },

    onFiltersUpdated(filters) {
      console.log('filters updated: ', filters);
      this.options.filters = filters;
      this.loadItems();
    },

    onColumnsSelected(selection) {
      console.log('selection:', selection);
      // this.selectedFields = selection;
      let profile = store.state.session.user.profile;
      const columns = selection.map((el) => el.value);

      let { order_list = {} } = profile;
      order_list.columns = columns;
      profile.order_list = order_list;

      try {
        Users.updateProfile(profile);
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style lang="sass">
.missing
  display: flex
  background-color: red
  height: 100%
  justify-content: center
  align-items: center
  color: white
  font:
    weight: bold
.done
  color: grey
.at-risk
  background-color: #ffd5d1
.late
  background-color: #f76557
.braille
  font:
    weight: bold
</style>
